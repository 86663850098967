import React, { useContext } from "react";
import {
  Modal,
  Select,
  IconButton,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import Cookies from "js-cookie";
import CloseIcon from "@mui/icons-material/Close";

const UpdateLead = ({
  updateModal,
  setUpdateModal,
  updateInputs,
  setUpdateInputs,
  handleUpdateInputs,
}) => {
  const { url, getLeads } = useContext(AppContext);
  const updateLead = async () => {
    const dsa_admin = Cookies.get("dsa_admin");
    try {
      const res = await axios.put(
        `${url}/user/lead/admin/${updateInputs.id}`,
        {
          status: updateInputs.status,
          comment: updateInputs.comment,
        },
        {
          headers: {
            token: dsa_admin,
          },
        }
      );
      if (res.data.success === true) {
        setUpdateInputs({
          status: "",
          comment: "",
          id: "",
        });
        setUpdateModal(false);
        getLeads();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const closeUpdateModal = () => {
    setUpdateModal(false);
    setUpdateInputs({
      status: "",
      comment: "",
      id: "",
    });
  };
  return (
    <>
      <Modal
        open={updateModal}
        onClose={() => setUpdateModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "500px",
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h2>Update lead</h2>
            <IconButton aria-label="close" onClick={closeUpdateModal}>
              <CloseIcon />
            </IconButton>
          </div>

          {/* Select Tag */}
          <Select
            value={updateInputs.status}
            onChange={handleUpdateInputs}
            fullWidth
            label="Select Option"
            variant="standard"
            sx={{ mb: 2 }}
            name="status"
          >
            <MenuItem value=""></MenuItem>
            <MenuItem value="uncontactable">Uncontactable</MenuItem>
            <MenuItem value="in_progress">In Progress</MenuItem>
            <MenuItem value="processed">Processed</MenuItem>
            <MenuItem value="not_eligible">Not Eligible</MenuItem>
          </Select>

          {/* Textarea */}
          <TextField
            value={updateInputs.comment}
            onChange={handleUpdateInputs}
            label="Comment"
            multiline
            fullWidth
            variant="standard"
            sx={{ mb: 2 }}
            name="comment"
          />

          {/* Button to submit the form */}
          <Button variant="contained" color="primary" onClick={updateLead}>
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default UpdateLead;
