import React, { useContext, useEffect, useState } from "react";
import { Button, IconButton, Modal, TextField, Switch } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const EditSecretKey = ({
  editSecretKeyModal,
  setEditSecretKeymodal,
  confirmSecretKey,
  getSecretKeys,
  edit,
  setEdit,
  editChecked,
  setEditChecked,
}) => {
  const { url } = useContext(AppContext);
  //
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  //
  async function editSecretKey(e) {
    e.preventDefault();
    const dsa_admin = Cookies.get("dsa_admin");
    try {
      const res = await axios.put(
        `${url}/secretkey/${confirmSecretKey}`,
        {
          secret_key: edit,
          active: editChecked,
        },
        {
          headers: {
            token: dsa_admin,
          },
        }
      );
      //
      if (res.data.success === true) {
        getSecretKeys();
        setEditSecretKeymodal(false);
        setIsError(false);
        setMessage(res.data.message);
        setEdit("");
        setEditChecked(false);
      } else if (res.data.success === false) {
        setIsError(true);
        setMessage(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  //
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={editSecretKeyModal}
        onClose={() => setEditSecretKeymodal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 400,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h2>Edit secret key</h2>
            <IconButton
              aria-label="close"
              onClick={() => setEditSecretKeymodal(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <>
            <form>
              <TextField
                label="Secret key"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="customerName"
                autoComplete="off"
                value={edit}
                onChange={(e) => setEdit(e.target.value)}
              />

              <div className="d-flex align-items-center justify-content-start gap-2 my-4">
                <Switch
                  active={editChecked}
                  onChange={() => setEditChecked(!editChecked)}
                  color="primary"
                  checked={editChecked === true ? true : false}
                />
                <p>{editChecked === true ? "Active" : "Inactive"}</p>
              </div>

              <div className="text-end">
                <p className={isError ? "text-danger" : "text-success"}>
                  {message}
                </p>
                <Button
                  color="primary"
                  variant="contained"
                  className="text-white"
                  onClick={editSecretKey}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default EditSecretKey;
