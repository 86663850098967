import React, { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../context/AppContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  IconButton,
  TextField,
  Checkbox,
  TablePagination,
  Button,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NoDataFound from "../components/NoDataFound";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateIcon from "@mui/icons-material/Create";
import axios from "axios";
import Cookies from "js-cookie";
import ViewLead from "../components/ViewLead";
import SearchFilter from "../components/SearchFilter";
import UpdateLead from "../components/UpdateLead";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const User = () => {
  const { url } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [leads, setLeads1] = useState([]);
  const [leads2, setLeads2] = useState([]);
  const [userIndex, setUserIndex] = useState(0);
  const getUsers = async () => {
    const dsa_admin = Cookies.get("dsa_admin");
    const res = await axios.get(`${url}/user/all`, {
      headers: {
        token: dsa_admin,
      },
    });
    if (res.data.success === true) {
      setUsers(res.data.result);
      setLeads1(res.data.result[userIndex].leads);
      setLeads2(res.data.result[userIndex].leads);
    }
  };
  //
  const selectUser = (index) => {
    setUserIndex(index);
    setUserNameDivState(false);
    resetFilter();
    setUserDetails(true);
  };
  //
  const [selected, setSelected] = useState([]);
  const handleSelected = (e, item) => {
    if (e.target.checked === true) {
      setSelected([...selected, item._id]);
    } else if (e.target.checked === false) {
      const filter = selected.filter((item2) => {
        return item2 !== item._id;
      });
      setSelected(filter);
    }
  };
  //
  const selectAllLeads = () => {
    const leadID = [];
    leads2.forEach((item) => {
      leadID.push(item._id);
    });
    setSelected(leadID);
  };
  //
  const [indexSorted, setIndexSorted] = useState(false);
  const indexArrow = useRef(null);
  const sortIndex = () => {
    setIndexSorted(!indexSorted);
    indexArrow.current.classList.toggle("rotate");
    setLeads2(leads2.reverse());
  };
  //
  const [creditorSorted, setCreditorSorted] = useState(false);
  const creditorArrow = useRef(null);
  const sortCreditors = () => {
    if (creditorSorted === true) {
      setCreditorSorted(false);
      const sorted = leads2.sort(
        (a, b) => b.creditorsdebtors - a.creditorsdebtors
      );
      setLeads2(sorted);
      creditorArrow.current.classList.add("rotate");
    } else if (creditorSorted === false) {
      setCreditorSorted(true);
      const sorted = leads2.sort(
        (a, b) => a.creditorsdebtors - b.creditorsdebtors
      );
      setLeads2(sorted);
      creditorArrow.current.classList.remove("rotate");
    }
  };
  //
  const [sliderValue1, setSliderValue1] = useState([0, 0]);
  const handleSliderChange1 = (e, newValue) => {
    setSliderValue1(newValue);
  };
  //
  const [status, setStatus] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const applyFilter = () => {
    let updated = leads;
    if (searchInput !== "") {
      updated = updated.filter((item) => {
        return (
          item.customerName.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.customerNumber
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          item.companyName.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
    if (sliderValue1[0] >= 1 && sliderValue1[1] <= 50) {
      updated = updated.filter((item) => {
        return (
          parseFloat(item.creditorsdebtors) >= sliderValue1[0] &&
          parseFloat(item.creditorsdebtors) <= sliderValue1[1]
        );
      });
    }
    if (status !== "") {
      updated = updated.filter((item) => {
        return item.status.toLowerCase() == status.toLowerCase();
      });
    }
    //
    setLeads2(updated);
    setFilterModal(false);
    setSelected([]);
  };
  const resetFilter = () => {
    setSliderValue1([0, 0]);
    setLeads2(leads);
    setFilterModal(false);
    setSearchInput("");
    setStatus("");
  };
  //
  const [viewModal, setViewModal] = useState(false);
  const [details, setDetails] = useState(null);
  const handleViewModal = (item) => {
    setViewModal(true);
    setDetails(item);
  };
  //
  const [filterModal, setFilterModal] = useState(false);
  //
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const [jumpToPage, setJumpToPage] = useState(1);
  const handleJumpToPage = () => {
    const totalPages = Math.ceil(totalCount / rowsPerPage);
    const newPage = Math.min(Math.max(jumpToPage - 1, 0), totalPages - 1);
    setPage(newPage);
  };
  //
  const [rowIndex, setRowIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setRowIndex(index);
  };
  const handleMouseLeave = () => {
    setRowIndex(null);
  };
  //
  const [updateModal, setUpdateModal] = useState(false);
  const [updateInputs, setUpdateInputs] = useState({
    status: "",
    comment: "",
    id: "",
  });
  const handleUpdateInputId = (item) => {
    setUpdateInputs({
      status: item.status,
      comment: item.comment,
      id: item._id,
    });
    setUpdateModal(true);
  };
  const handleUpdateInputs = (e) => {
    const { name, value } = e.target;
    setUpdateInputs({
      ...updateInputs,
      [name]: value,
    });
  };
  //
  useEffect(() => {
    getUsers();
  }, [userIndex, rowsPerPage, page]);
  //
  const userNameDiv = useRef(null);
  const [userNameDivState, setUserNameDivState] = useState(false);
  const toggleUserNameDiv = () => {
    setUserNameDivState(!userNameDivState);
  };
  //
  const [userDetails, setUserDetails] = useState(true);
  const toggleUserDetails = (value) => {
    setUserDetails(value);
  };
  //
  const [leadUniqueID, setLeadUniqueID] = useState([]);
  const getLeadUniqueID = async () => {
    const dsa_admin = Cookies.get("dsa_admin");
    try {
      const res = await axios.post(
        `${url}/leaduniqueid/admin`,
        {
          userId: users[userIndex]._id,
        },
        {
          headers: {
            token: dsa_admin,
          },
        }
      );
      console.log(res.data);
      if (res.data.success === true) {
        setLeadUniqueID(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLeadUniqueID();
  }, [userIndex, users]);
  return (
    <>
      <div className="container-fluid p-2">
        {users.length === 0 ? (
          <p className="text-center">No data found</p>
        ) : (
          <>
            <div className="user-container">
              <div
                className={`user-name ${
                  userNameDivState === true ? "active" : ""
                }`}
                ref={userNameDiv}
              >
                <div className="text-end">
                  <Button
                    color="primary"
                    variant="contained"
                    aria-label="upload file"
                    className="mb-2"
                    onClick={toggleUserNameDiv}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                <>
                  {users.map((item, index) => {
                    return (
                      <p
                        key={index}
                        className={index === userIndex ? "active" : ""}
                        onClick={() => selectUser(index)}
                      >
                        {item.name}
                      </p>
                    );
                  })}
                </>
              </div>
              <div className="user-data">
                <>
                  <div className="d-flex align-items-center justify-content-start gap-2">
                    <Button
                      color="primary"
                      variant="contained"
                      aria-label="upload file"
                      className="mb-2"
                      onClick={toggleUserNameDiv}
                    >
                      <ArrowBackIcon />
                    </Button>
                    <ButtonGroup className="mb-2">
                      <Button
                        color="primary"
                        variant={userDetails ? "contained" : "outlined"}
                        aria-label="upload file"
                        onClick={() => toggleUserDetails(true)}
                      >
                        detail
                      </Button>

                      <Button
                        color="primary"
                        variant={!userDetails ? "contained" : "outlined"}
                        aria-label="upload file"
                        onClick={() => toggleUserDetails(false)}
                      >
                        leads
                      </Button>
                    </ButtonGroup>
                  </div>
                  <>
                    {userDetails === true ? (
                      <>
                        <div>
                          <p>Name: {users[userIndex].name}</p>
                          <p>Username: {users[userIndex].username}</p>
                          <p>Mobile: {users[userIndex].mobile}</p>
                          <p>Email: {users[userIndex].email}</p>
                          <p>
                            Status:{" "}
                            {users[userIndex].active ? "Active" : "Inactive"}
                          </p>
                          <p>Leads: {users[userIndex].leads.length}</p>
                        </div>
                        <hr />
                        <div>
                          <h2 className="mb-2">Lead unique ID(s)</h2>
                          <TableContainer
                            component={Paper}
                            className="table-container"
                          >
                            <Table>
                              <TableHead>
                                <TableRow className="table-background">
                                  <TableCell
                                    className="border"
                                    sx={{ minWidth: "100px" }}
                                  >
                                    Sr No
                                  </TableCell>
                                  <TableCell
                                    className="border"
                                    sx={{ minWidth: "200px" }}
                                  >
                                    Name
                                  </TableCell>
                                  <TableCell
                                    className="border"
                                    sx={{ minWidth: "150px" }}
                                  >
                                    Status
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {leadUniqueID.map((item, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      onMouseEnter={() =>
                                        handleMouseEnter(index)
                                      }
                                      onMouseLeave={handleMouseLeave}
                                      className={
                                        index === rowIndex ||
                                        selected.includes(item._id)
                                          ? "table-background"
                                          : ""
                                      }
                                    >
                                      <TableCell className="border">
                                        {index + 1}
                                      </TableCell>
                                      <TableCell className="border">
                                        <span className="text-clamp">
                                          {item.leadUniqueID}
                                        </span>
                                      </TableCell>
                                      <TableCell className="border">
                                        <p>Test</p>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="top-bar-grid mb-2">
                          <div>
                            <TextField
                              label="Search"
                              variant="standard"
                              fullWidth
                              value={searchInput}
                              onChange={(e) => setSearchInput(e.target.value)}
                              onKeyUp={applyFilter}
                              autoComplete="off"
                              disabled={leads.length === 0 ? true : false}
                            />
                          </div>
                          <div className="text-end">
                            <IconButton
                              color="primary"
                              aria-label="upload file"
                              component="span"
                              onClick={() => setFilterModal(true)}
                              disabled={leads.length === 0 ? true : false}
                            >
                              <TuneIcon />
                            </IconButton>
                          </div>
                        </div>
                        {leads2.length !== 0 ? (
                          <>
                            <TableContainer
                              component={Paper}
                              className="table-container"
                            >
                              <Table>
                                <TableHead>
                                  <TableRow className="table-background">
                                    <TableCell
                                      className="border"
                                      sx={{ minWidth: "75px" }}
                                    >
                                      {selected.length === leads2.length ? (
                                        <IconButton
                                          color="primary"
                                          onClick={() => setSelected([])}
                                        >
                                          <IndeterminateCheckBoxIcon />
                                        </IconButton>
                                      ) : (
                                        <Checkbox onChange={selectAllLeads} />
                                      )}
                                    </TableCell>
                                    <TableCell
                                      className="border"
                                      sx={{ minWidth: "100px" }}
                                    >
                                      Sr No
                                      <ArrowDownwardIcon
                                        onClick={sortIndex}
                                        className="indexArrow"
                                        ref={indexArrow}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="border"
                                      sx={{ minWidth: "200px" }}
                                    >
                                      Name
                                    </TableCell>
                                    <TableCell
                                      className="border"
                                      sx={{ minWidth: "150px" }}
                                    >
                                      Mobile number
                                    </TableCell>
                                    <TableCell
                                      className="border"
                                      sx={{ minWidth: "150px" }}
                                    >
                                      Email address
                                    </TableCell>
                                    <TableCell
                                      className="border"
                                      sx={{ minWidth: "200px" }}
                                    >
                                      Company name
                                    </TableCell>
                                    <TableCell
                                      className="border"
                                      sx={{ minWidth: "200px" }}
                                    >
                                      City
                                    </TableCell>
                                    <TableCell
                                      className="border"
                                      sx={{ minWidth: "250px" }}
                                    >
                                      Total Creditors/Debtors{" "}
                                      <ArrowDownwardIcon
                                        onClick={sortCreditors}
                                        className="creditorArrow"
                                        ref={creditorArrow}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="border"
                                      sx={{ minWidth: "250px" }}
                                    >
                                      Status
                                    </TableCell>
                                    <TableCell
                                      className="border text-end"
                                      sx={{ minWidth: "200px" }}
                                    >
                                      {selected.length === 0 ? (
                                        ""
                                      ) : (
                                        <>
                                          {selected.length === leads2.length ? (
                                            ""
                                          ) : (
                                            <IconButton
                                              color="primary"
                                              onClick={() => setSelected([])}
                                            >
                                              <IndeterminateCheckBoxIcon />
                                            </IconButton>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {leads2.map((item, index) => {
                                    return (
                                      <TableRow
                                        key={index}
                                        onMouseEnter={() =>
                                          handleMouseEnter(index)
                                        }
                                        onMouseLeave={handleMouseLeave}
                                        className={
                                          index === rowIndex ||
                                          selected.includes(item._id)
                                            ? "table-background"
                                            : ""
                                        }
                                      >
                                        <TableCell
                                          className="border"
                                          sx={{ width: "70px" }}
                                        >
                                          <Checkbox
                                            onChange={(e) =>
                                              handleSelected(e, item)
                                            }
                                            checked={selected.includes(
                                              item._id
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell className="border">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell className="border">
                                          <span className="text-clamp">
                                            {item.customerName}
                                          </span>
                                        </TableCell>
                                        <TableCell className="border">
                                          {item.customerNumber}
                                        </TableCell>
                                        <TableCell className="border">
                                          {item.email}
                                        </TableCell>
                                        <TableCell className="border">
                                          <span className="text-clamp">
                                            {item.companyName}
                                          </span>
                                        </TableCell>
                                        <TableCell className="border">
                                          {item.city}
                                        </TableCell>
                                        <TableCell className="border">
                                          {item.creditorsdebtors}
                                        </TableCell>
                                        <TableCell className="border">
                                          {item.status}
                                        </TableCell>
                                        <TableCell className="border">
                                          <ButtonGroup>
                                            <IconButton
                                              color="primary"
                                              onClick={() =>
                                                handleViewModal(item)
                                              }
                                            >
                                              <VisibilityIcon />
                                            </IconButton>

                                            <IconButton
                                              color="primary"
                                              onClick={() =>
                                                handleUpdateInputId(item)
                                              }
                                            >
                                              <CreateIcon />
                                            </IconButton>
                                          </ButtonGroup>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <TablePagination
                              component="div"
                              count={totalCount}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              rowsPerPageOptions={[25, 50, 75, 100]}
                            />
                            <div className="d-flex align-items-end justify-content-end gap-2">
                              <TextField
                                label="Jump to Page"
                                type="number"
                                value={jumpToPage}
                                onChange={(e) => setJumpToPage(e.target.value)}
                                variant="standard"
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                className="text-white"
                                onClick={handleJumpToPage}
                              >
                                Jump
                              </Button>
                            </div>
                          </>
                        ) : (
                          <NoDataFound />
                        )}
                      </>
                    )}
                  </>
                </>

                {/* View */}
                <ViewLead
                  viewModal={viewModal}
                  setViewModal={setViewModal}
                  details={details}
                />

                {/* Update */}
                <UpdateLead
                  updateModal={updateModal}
                  setUpdateModal={setUpdateModal}
                  updateInputs={updateInputs}
                  setUpdateInputs={setUpdateInputs}
                  handleUpdateInputs={handleUpdateInputs}
                  getUsers={getUsers}
                />

                {/* Search Filter */}
                <SearchFilter
                  filterModal={filterModal}
                  setFilterModal={setFilterModal}
                  applyFilter={applyFilter}
                  resetFilter={resetFilter}
                  sliderValue1={sliderValue1}
                  handleSliderChange1={handleSliderChange1}
                  minVal1={1}
                  maxVal1={50}
                  title1="Total Creditors/Debtors"
                  status={status}
                  setStatus={setStatus}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default User;
