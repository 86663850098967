import React, { useContext, useEffect, useMemo, useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { IconButton, TextField } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import CloseIcon from "@mui/icons-material/Close";

const EditProfile = ({ editProfileModal, setEditProfileModal }) => {
  const { url, admin, getAdmin } = useContext(AppContext);
  //
  const [inputs, setInputs] = useState({
    name: "",
    username: "",
  });
  useEffect(() => {
    if (admin !== null) {
      setInputs({
        username: admin.username,
        name: admin.name,
      });
    }
  }, [admin]);
  //
  const [message, setMessage] = useState("");
  //
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const editProfile = async (e) => {
    e.preventDefault();
    const dsa_admin = Cookies.get("dsa_admin");
    if (!inputs.username) {
      setMessage("Username required");
    } else if (!inputs.name) {
      setMessage("Name required");
    } else {
      try {
        const res = await axios.put(
          `${url}/admin/editprofile`,
          {
            username: inputs.username,
            name: inputs.name,
          },
          {
            headers: {
              token: dsa_admin,
            },
          }
        );
        if (res.data.success === true) {
          getAdmin();
          setEditProfileModal(false);
          setInputs({
            username: "",
            name: "",
          });
        } else {
          setMessage(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //
  const [toggle, setToggle] = useState(false);
  const [passwords, setPasswords] = useState({
    current: "",
    new: "",
  });
  const handlePasswords = (e) => {
    const { name, value } = e.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });
  };
  //
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  //
  const changePassword = async (e) => {
    e.preventDefault();
    const dsa_admin = Cookies.get("dsa_admin");
    try {
      const res = await axios.put(
        `${url}/admin/password`,
        {
          currentPassword: passwords.current,
          newPassword: passwords.new,
        },
        {
          headers: {
            token: dsa_admin,
          },
        }
      );
      if (res.data.success === true) {
        setEditProfileModal(false);
      } else {
        setMessage(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        open={editProfileModal}
        onClose={() => setEditProfileModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          {admin !== null ? (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <h2>{!toggle ? "Edit profile" : "Change password"}</h2>
                <IconButton
                  aria-label="close"
                  onClick={() => setEditProfileModal(false)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <form>
                {!toggle ? (
                  <>
                    <TextField
                      label="Name"
                      variant="standard"
                      fullWidth
                      type="text"
                      margin="normal"
                      autoComplete="off"
                      name="name"
                      value={inputs.name}
                      onChange={handleInputs}
                    />
                    <TextField
                      label="Username"
                      variant="standard"
                      fullWidth
                      type="text"
                      margin="normal"
                      autoComplete="off"
                      name="username"
                      value={inputs.username}
                      onChange={handleInputs}
                    />
                    <p className="text-end text-danger">{message}</p>
                    <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
                      <Button
                        color="primary"
                        variant="contained"
                        className="text-white"
                        onClick={() => setEditProfileModal(false)}
                      >
                        cancel
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        className="text-white"
                        onClick={editProfile}
                      >
                        Save
                      </Button>
                    </div>
                    <hr />
                    <div className="text-end mt-4">
                      <Button
                        color="primary"
                        variant="contained"
                        className="text-white"
                        onClick={() => setToggle(true)}
                      >
                        Change password
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <TextField
                      label="Current password"
                      variant="standard"
                      fullWidth
                      type="text"
                      margin="normal"
                      autoComplete="off"
                      name="current"
                      value={passwords.current}
                      onChange={handlePasswords}
                    />
                    <TextField
                      label="New password"
                      variant="standard"
                      fullWidth
                      type="text"
                      margin="normal"
                      autoComplete="off"
                      name="new"
                      value={passwords.new}
                      onChange={handlePasswords}
                    />
                    <p className="text-danger text-end">{message}</p>
                    <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
                      <Button
                        color="primary"
                        variant="contained"
                        className="text-white"
                        onClick={() => setToggle(false)}
                      >
                        cancel
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        className="text-white"
                        onClick={changePassword}
                      >
                        Save
                      </Button>
                    </div>
                  </>
                )}
              </form>
            </>
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </>
  );
};

export default EditProfile;
