import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import ConfirmAccept from "../components/ConfirmAccept";
import ConfirmReject from "../components/ConfirmReject";
import NoDataFound from "../components/NoDataFound";

const Request = () => {
  const { url } = useContext(AppContext);
  const [accounts, setAccounts] = useState([]);
  const getRequest = async () => {
    const dsa_admin = Cookies.get("dsa_admin");
    try {
      const res = await axios.get(`${url}/user/request`, {
        headers: {
          token: dsa_admin,
        },
      });
      if (res.data.success === true) {
        setAccounts(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getRequest();
  }, []);
  //
  const [acceptModal, setAcceptModal] = useState(false);
  const [confirmAccept, setConfirmAccept] = useState(null);
  const accept = (item) => {
    setAcceptModal(true);
    setConfirmAccept(item);
  };
  //
  const [rejectModal, setRejectModal] = useState(false);
  const [confirmReject, setConfirmReject] = useState(null);
  const reject = (item) => {
    setRejectModal(true);
    setConfirmReject(item);
  };
  //
  const [rowIndex, setRowIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setRowIndex(index);
  };
  const handleMouseLeave = () => {
    setRowIndex(null);
  };
  return (
    <>
      <div className="container-fluid p-2">
        {accounts.length !== 0 ? (
          <>
            <h2>Pending requests</h2>
            <TableContainer component={Paper} className="table-container mt-4">
              <Table>
                <TableHead>
                  <TableRow className="table-background">
                    <TableCell className="border">Sr No</TableCell>
                    <TableCell className="border">Name</TableCell>
                    <TableCell className="border">Mobile number</TableCell>
                    <TableCell className="border">Email address</TableCell>
                    <TableCell className="border"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts.map((item, index) => (
                    <TableRow
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      className={index === rowIndex ? "table-background" : ""}
                    >
                      <TableCell className="border">{index + 1}</TableCell>
                      <TableCell className="border">{item.name}</TableCell>
                      <TableCell className="border">{item.mobile}</TableCell>
                      <TableCell className="border">{item.email}</TableCell>
                      <TableCell className="border">
                        <div className="d-flex align-items-center justify-content-end gap-2">
                          <Button
                            variant="contained"
                            className="text-white"
                            color="primary"
                            onClick={() => accept(item)}
                          >
                            Accept
                          </Button>
                          <Button
                            variant="contained"
                            color="danger"
                            className="text-white"
                            onClick={() => reject(item)}
                          >
                            Reject
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/*  */}
            <ConfirmAccept
              acceptModal={acceptModal}
              setAcceptModal={setAcceptModal}
              confirmAccept={confirmAccept}
              getRequest={getRequest}
            />

            {/*  */}
            <ConfirmReject
              rejectModal={rejectModal}
              setRejectModal={setRejectModal}
              confirmReject={confirmReject}
              getRequest={getRequest}
            />
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};

export default Request;
