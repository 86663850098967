import React, { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";
import ConfirmLogout from "../components/ConfirmLogout";
import ConfirmDeleteAccount from "../components/ConfirmDeleteAccount";
import { Button } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import EditProfile from "../components/EditProfile";

const Profile = () => {
  const { admin } = useContext(AppContext);
  const [logoutModal, setLogoutModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  return (
    <>
      <div className="container-fluid p-2">
        {admin !== null ? (
          <>
            <div>
              <p>
                Name: <span className="fw-bold">{admin.name}</span>
              </p>
              <p>
                Username: <span className="fw-bold">{admin.username}</span>
              </p>
              <p>
                Role: <span className="fw-bold">{admin.role}</span>
              </p>
              <p>
                Account created at:{" "}
                <span className="fw-bold">
                  {moment(admin.createdAt).format("Do MMMM YYYY, h:mm:ss a")}
                </span>
              </p>

              <div className="d-flex align-items-sm-center align-items-start justify-content-start gap-2 mt-4 flex-sm-row flex-column">
                {admin.role === "superadmin" ? (
                  <Link to="/addaccount">
                    <Button variant="contained" className="text-white">
                      Add account
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-white"
                    onClick={() => setEditProfileModal(true)}
                  >
                    Edit profile
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="danger"
                  className="text-white"
                  onClick={() => setLogoutModal(true)}
                >
                  Logout
                </Button>
              </div>
            </div>
            <hr />
            <div className="text-end">
              <Button
                variant="contained"
                color="danger"
                className="text-white"
                onClick={() => setDeleteAccountModal(true)}
              >
                Delete account
              </Button>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      <ConfirmLogout
        logoutModal={logoutModal}
        setLogoutModal={setLogoutModal}
      />

      <ConfirmDeleteAccount
        deleteAccountModal={deleteAccountModal}
        setDeleteAccountModal={setDeleteAccountModal}
      />

      <EditProfile
        editProfileModal={editProfileModal}
        setEditProfileModal={setEditProfileModal}
      />
    </>
  );
};

export default Profile;
