import React, { useContext } from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const ConfirmReject = ({
  rejectModal,
  setRejectModal,
  confirmReject,
  getRequest,
}) => {
  const { url, getRequestedCount } = useContext(AppContext);
  const rejectRequest = async () => {
    const dsa_admin = Cookies.get("dsa_admin");
    try {
      const res = await axios.delete(
        `${url}/user/reject/${confirmReject._id}`,
        {
          headers: {
            token: dsa_admin,
          },
        }
      );
      if (res.data.success === true) {
        getRequest();
        setRejectModal(false);
        getRequestedCount();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        open={rejectModal}
        onClose={() => setRejectModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <p className="mb-2">
              Reject {confirmReject !== null && confirmReject.name}?
            </p>
          </>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Button
              className="text-white"
              variant="contained"
              onClick={rejectRequest}
              color="danger"
            >
              Yes
            </Button>
            <Button
              color="primary"
              onClick={() => setRejectModal(false)}
              variant="contained"
              className="text-white"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmReject;
