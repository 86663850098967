import { useContext, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Navbar from "../components/Navbar";

const Auth = ({ Component }) => {
  const { url, setAdmin } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    const dsa_admin = Cookies.get("dsa_admin");
    if (!dsa_admin) {
      navigate("/login", { replace: true });
    }
    const Authenticate = async () => {
      const res = await axios.get(`${url}/admin/admin`, {
        headers: {
          token: dsa_admin,
        },
      });
      if (res.data.success === true) {
        setAdmin(res.data.admin);
      } else if (!res.data) {
        navigate("/login", { replace: true });
      }
    };
    Authenticate();
  }, []);
  return (
    <>
      <Navbar />
      <Component />
    </>
  );
};

export default Auth;
